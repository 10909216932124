require('bootstrap')


$(document).ready(() => {
  $('#send_code_button').on('click', () => {
    $('.tooltip').remove()
    const email = $('#user_email_input').prop('value')
    if (email) {
      $('#send_code_user_email_input').prop('value', email)
      const form = document.querySelector('#send_code_form')
      form.dispatchEvent(new Event('submit', {bubbles: true}))
    } else {
      const email_input = document.querySelector('#user_email_input')
      email_input.validity.valueMissing
      email_input.reportValidity()
    }
  })

  $('#send_code_form').on('ajax:success', (event) => {
    const button = $('#send_code_button')
    button.text('Code Sent')
    button.prop('disabled', true)
  })

  $('#login_form').on('ajax:success', (event) =>
    window.location.replace('/machines')
  ).on('ajax:error', (event) => {
    $('#login_failed_alert').collapse('show')
    setTimeout(() => location.reload(), 3000)
  })
})
